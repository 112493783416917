import React, { useState } from 'react'
import {
  Heading,
  VStack,
  IconButton,
  SlideFade,
  ScaleFade,
  Spinner,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'

const Start = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)

  setTimeout(() => setDoneLoading(true), 2000)

  return (
    <VStack spacing={8}>
      {doneLoading ? null : <Spinner size="xl" />}
      <ScaleFade in={doneLoading}>
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              [
                'Hey M, you know the drill by now hehe!^2000',
                "It's never too late to hope it all goes smoothly 🙃🙃🙃^1500",
                "Okayyyy, let's get started!",
              ].join('\n'),
            ]}
            typeSpeed={40}
            backSpeed={50}
            backDelay={2000}
            onComplete={() => setDoneTyping(true)}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/history')}
            />
          </SlideFade>
        </Heading>
      </ScaleFade>
    </VStack>
  )
}

export default withRouter(Start)
