import React, { useState } from 'react'
import { Heading, Spinner, VStack } from '@chakra-ui/react'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from '@sidpagariya/react-sound'
import Confetti from 'react-dom-confetti'

const Finally = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [volume, setVolume] = useState(50)
  let delayT = 500
  const config = {
    angle: '90',
    spread: 180,
    startVelocity: 50,
    elementCount: '200',
    dragFriction: 0.12,
    duration: '50000',
    stagger: '3',
    width: '11px',
    height: '11px',
    perspective: '200px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 20) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      <Confetti active={doneTyping} config={config} />
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              'I love you baby and I love you doll ❤️^1000',
              'M, I really hope you had a good time tonight 🥳^1000',
              'Thanks for spending the past 3 months with me 🥰^200',
              'You really mean a lot to me 💎',
              'Thanks for an amazing date babe 🥺❤️❤️❤️',
            ]}
            smartBackspace
            typeSpeed={40}
            backSpeed={50}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 5000 + delayT)
              setDoneTyping(true)
            }}
          />
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/i_love_you_baby_i_love_you_doll.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(Finally)
