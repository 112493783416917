import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
  Button,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from 'react-sound'

const Quiz = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [nextRevealed, setNextRevealed] = useState(false)
  let [volume, setVolume] = useState(50)
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 0) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              "We know each other fairly well, but let's put it to the test!",
            ]}
            smartBackspace
            typeSpeed={40}
            backSpeed={50}
            backDelay={1000}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 10000 + delayT)
              setDoneTyping(true)
            }}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <Button
              onClick={() => {
                window.open('https://www.kahoot.it')
                setNextRevealed(true)
              }}
            >
              Let's do a fun Kahoot!! (Enter code 672863) 😤🙌
            </Button>
          </SlideFade>
          <SlideFade in={nextRevealed}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/google-art')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/kahoot-lobby.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(Quiz)
