import '@fontsource/rubik'
import '@fontsource/poppins'
import React from 'react'
import { ChakraProvider, Box, Grid } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import theme from './theme'
import Home from './pages/Home'
import Start from './pages/Start'
import History from './pages/History'
import Quiz from './pages/Quiz'
import './App.css'
import GoogleArt from './pages/GoogleArt'
import Finally from './pages/Finally'

function App() {
  const history = createBrowserHistory()
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Box>
            <Router history={history}>
              <Switch>
                <Route path="/start">
                  <Start />
                </Route>
                <Route path="/history">
                  <History />
                </Route>
                <Route path="/quiz">
                  <Quiz />
                </Route>
                <Route path="/google-art">
                  <GoogleArt />
                </Route>
                <Route path="/finally">
                  <Finally />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Router>
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  )
}

export default App
