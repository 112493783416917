import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from 'react-sound'

const History = (props) => {
  const maxVolume = 20
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [firstDoneTyping, setFirstDoneTyping] = useState(false)
  let [volume, setVolume] = useState(maxVolume)
  let firstTyping = undefined
  let secondTyping = undefined
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < maxVolume && volume > 0) {
    setTimeout(() => setVolume(volume - 2), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              [
                "It's been a month since we've been official,^1000",
                'But more than three since we first met!^2000',
                "I'll keep it brief.^2000",
              ].join('\n'),
            ]}
            typeSpeed={40}
            backSpeed={50}
            backDelay={2000}
            typedRef={(type) => (firstTyping = type)}
            onComplete={() => {
              setTimeout(() => {
                firstTyping.destroy()
                secondTyping.start()
              }, 1000)
              setFirstDoneTyping(true)
            }}
          />

          <br />

          <Typed
            strings={[
              'Mahek Bhavesh Parekh...^2000',
              'This past month has brought me so much happiness',
              'This past month has brought me so much love',
              'This past month has brought me so many feelings',
              'This past month has brought me so many butterflies hehe',
              'This past month has intensified my feelings for you',
              'This past month has made me appreciate every part of you',
              'From your cute smile',
              'To that very wholesome "annoying" sound you make hehehe',
              'To the amount of passion you have for your work',
              'To your display of love, kindness, and selflessness',
              'To the outfits you wear and the way you move your hair',
              'There is truly nothing to hate about you',
              'I hate you too, M 🥺❤️❤️❤️',
            ]}
            smartBackspace
            stopped
            typeSpeed={40}
            backSpeed={50}
            backDelay={1000}
            typedRef={(type) => (secondTyping = type)}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 3000 + delayT)
              setDoneTyping(true)
            }}
            showCursor={firstDoneTyping ? true : false}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/quiz')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/strawberries_and_cigarettes.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(History)
