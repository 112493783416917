import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
  Button,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from 'react-sound'

const Quiz = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [nextRevealed, setNextRevealed] = useState(false)
  let [volume, setVolume] = useState(50)
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 0) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              'Creativity is at the heart of your work, at the heart of your life',
              "But this date wasn't creative enough so far, so here's something that is!",
            ]}
            smartBackspace
            typeSpeed={40}
            backSpeed={50}
            backDelay={1000}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 10000 + delayT)
              setDoneTyping(true)
            }}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <Button
              onClick={() => {
                window.open(
                  'https://artsandculture.google.com/partner/moma-the-museum-of-modern-art'
                )
                setNextRevealed(true)
              }}
            >
              Look at some art together from your fav city?!
            </Button>
          </SlideFade>
          <SlideFade in={nextRevealed}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/finally')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/umbrella.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(Quiz)
